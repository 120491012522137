
import React from 'react';

const userContext = React.createContext();

function getUserFromLocalStorage() {
    return localStorage.getItem('letsInfluence_user') ? JSON.parse(localStorage.getItem('letsInfluence_user')) : null
}

function UserContext(props) {
    const [user, setUser] = React.useState(getUserFromLocalStorage());
    const [loader, setLoader] = React.useState(false);

    React.useEffect(() => {
        localStorage.setItem('letsInfluence_user', JSON.stringify(user))
    }, [user])

    return (
        <userContext.Provider value={{ user, setUser, loader, setLoader }} >
            {props.children}
        </userContext.Provider>
    )
}

export { userContext, UserContext }